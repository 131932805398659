<template>
    <el-table :data="tableData" stripe style="width: 100%" :height="tableMaxHeight" :show-summary="showSummary"
              :header-cell-style="{background:'#4194F2',color:'#fff',borderRight:'1px solid #4194F2'}"
              @selection-change="handleSelectionChange" :summary-method="getSummaries" :span-method="objectSpanMethod"
              :border="border" :highlight-current-row="true"  :row-key="rowKey">
        <el-table-column type="selection" width="55" v-if="selection"></el-table-column>
        <slot></slot>
    </el-table>
</template>

<script>
export default {
    name: "publicTable",
    data() {
        return {
            tableMaxHeight: 0,
            tableData: [],
            selection: false,
            multipleSelection: [],
            showSummary: false,
            border: false,
            rowKey:''
        }
    },
    props: {
        publicTableOtherHeight: Number,
        publicTableData: Array,
        publicTableConfigure: Object,
    },
    watch: {
        publicTableConfigure() {
            this.configureChange()
        },
        publicTableData() {
            this.publicTableDataChange()
        },
        publicTableOtherHeight() {
            this.calculateHeight()
        }
    },
    created() {
        this.calculateHeight()
        this.publicTableDataChange()
        this.configureChange()
    },
    methods: {
        objectSpanMethod({row, columnIndex}) {
            if (columnIndex == 0) {
                if (row.mergeObj) {
                    return row.mergeObj
                }
            }
        },
        getSummaries(param) {
            const {columns, data} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计'
                    return
                }
                const values = data.map((item) => Number(item[column.property]))
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        }
                        else {
                            return prev
                        }
                    }, 0)
                }
                else {
                    sums[index] = 'N/A'
                }
            })
            let man_count_list = [];
            let woman_count_list = [];
            man_count_list = data.map((item) => Number(item['man_count']))
            woman_count_list = data.map((item) => Number(item['woman_count']))
            let man_count_total = man_count_list.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                }
                else {
                    return prev
                }
            }, 0)
            let woman_count_total = woman_count_list.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                }
                else {
                    return prev
                }
            }, 0)
            sums[5] = man_count_total + ' / ' + woman_count_total;
            return sums
        },
        configureChange() {
            if (this.publicTableConfigure) {
                let publicTableConfigure = JSON.parse(JSON.stringify(this.publicTableConfigure))
                for (let k in publicTableConfigure) {
                    this[k] = publicTableConfigure[k]
                }
            }
        },
        handleSelectionChange(val) {
            if (this.selection) {
                this.$emit('selAllDataFun', val)
            }
        },
        publicTableDataChange() {
            this.tableData = this.publicTableData;
            this.multipleSelection = [];
        },
        calculateHeight() {
            this.$nextTick(() => {
                let domHeight = document.documentElement.clientHeight;
                this.tableMaxHeight = domHeight - this.publicTableOtherHeight - 186;
            })
        }
    }
}
</script>

<style scoped>

</style>
